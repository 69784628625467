.carousel {
  margin: 0 auto;
  padding: 20px 90px;
  max-width: 1640px;
}

.carousel :global(.rec-carousel-item) {
  margin: auto 0;
}

.carousel :global(button.rec-arrow) {
  background-color: rgb(185, 185, 185);
}

.carousel :global(button.rec-arrow:hover:not(:disabled)),
.carousel :global(button.rec-arrow:focus:not(:disabled)) {
  background-color: #11a00d;
}

.carousel :global(.rec-pagination) {
  padding: 20px 0;
}

.carousel :global(button.rec-dot_active) {
  background-color: #11a00d;
  box-shadow: 0 0 1px 3px#11a00d;
}

.carousel :global(button.rec-dot:hover),
.carousel :global(button.rec-dot:active),
.carousel :global(button.rec-dot:focus) {
  box-shadow: 0 0 1px 3px #11a00d;
}

@media only screen and (max-width: 600px) {
  .carousel {
    margin: 0 auto;
    padding: 20px 0px;
  }
}

@media only screen and (max-width: 480px) {
  .carousel {
    margin: 0 auto;
    padding: 20px 0px;
  }
}
