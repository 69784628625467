.Components_carousel__sOBHv {
  margin: 0 auto;
  padding: 20px 90px;
  max-width: 1640px;
}

.Components_carousel__sOBHv .rec-carousel-item {
  margin: auto 0;
}

.Components_carousel__sOBHv button.rec-arrow {
  background-color: rgb(185, 185, 185);
}

.Components_carousel__sOBHv button.rec-arrow:hover:not(:disabled),
.Components_carousel__sOBHv button.rec-arrow:focus:not(:disabled) {
  background-color: #11a00d;
}

.Components_carousel__sOBHv .rec-pagination {
  padding: 20px 0;
}

.Components_carousel__sOBHv button.rec-dot_active {
  background-color: #11a00d;
  box-shadow: 0 0 1px 3px#11a00d;
}

.Components_carousel__sOBHv button.rec-dot:hover,
.Components_carousel__sOBHv button.rec-dot:active,
.Components_carousel__sOBHv button.rec-dot:focus {
  box-shadow: 0 0 1px 3px #11a00d;
}

@media only screen and (max-width: 600px) {
  .Components_carousel__sOBHv {
    margin: 0 auto;
    padding: 20px 0px;
  }
}

@media only screen and (max-width: 480px) {
  .Components_carousel__sOBHv {
    margin: 0 auto;
    padding: 20px 0px;
  }
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_Condensed_c70d84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/290276bbc0aaa986-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_Condensed_c70d84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/109c3d45b531622a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_Condensed_c70d84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5873c060a9d5dc5f-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_Condensed_c70d84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ea72d168b005c995-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_Condensed_c70d84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/07f5ed10b18438e2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_Condensed_c70d84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/db3b7c1daf911997-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_Condensed_c70d84';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a5bbb863eaccdfa2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Condensed_Fallback_c70d84';src: local("Arial");ascent-override: 104.44%;descent-override: 27.48%;line-gap-override: 0.00%;size-adjust: 88.83%
}.__className_c70d84 {font-family: '__Roboto_Condensed_c70d84', '__Roboto_Condensed_Fallback_c70d84';font-weight: 400;font-style: normal
}

